import { isNil } from 'lodash-es';
import { select } from 'd3-selection';

export const highlightLines = (baseSelection, linesArray, highlightColor) => {
    if (linesArray instanceof Array) {
        linesArray.forEach((line) => {
            baseSelection.select(`li:nth-child(${line})`)
                .style('background', highlightColor, 'important')
                .style('border-radius', '5px');
        });
    }
};

export const updateSvgImage = (graphId, state, previousSvgFileName) => {
    const {
        svgFileName,
    } = state;
    if (isNil(svgFileName)) {
        const graph = select(`#${graphId} .imageDiv`);
        graph
            .transition()
            .duration(250)
            .style('opacity', 0);
    }
    if (svgFileName !== previousSvgFileName) {
        const html = svgFileName
            ? `<img src="dist/images/${svgFileName}.svg" />`
            : null;
        const graph = select(`#${graphId} .imageDiv`);
        graph
            .transition()
            .duration(250)
            .style('opacity', 0)
            .on('end', () => {
                graph
                    .html(html)
                    .transition()
                    .duration(250)
                    .style('opacity', svgFileName ? 1 : 0);
            });
    }
};

export const getContinentColours = (dataProcessed) => {

    return [
        {name: "North America", code: "#004356"},
        {name: "Europe", code: "#008087"},
        {name: "Asia", code: "#b11d18"},
        {name: "South America", code: "#be7d1e"},
        {name: "Africa", code: "#ea4c00"},
        {name: "Oceania", code: "#ff9f00"},
    ];
};


const calculate2NodeOverlapping = (node1, node2) => {
    let dis = Math.sqrt(Math.pow((node1.x - node2.x), 2) + Math.pow((node1.y - node2.y), 2));
    //console.log(dis, node1.r, node2.r, (node1.r + node2.r), node1, node2)
    if (dis >= (node1.r + node2.r)){
        return null;
    }
    else{
        let diff_dis = (node1.r + node2.r) - dis;
        let dy = (node2.y - node1.y) / diff_dis;
        let dx = (node2.x - node1.x) / diff_dis;
        let theta =  Math.atan2(dy, dx)

        //theta *= 180 / Math.PI;
        //console.log(dis, node1.r, node2.r, (node1.r + node2.r), node1, node2)
        return {
            x: Math.cos(theta) * diff_dis,
            y: Math.sin(theta) * diff_dis
        }
    }
}

export const getWorldNodesAdj = (root) => {
    var names = {};

    root.forEach(function(node){
        if (!names[node.parent.data.name]){
            names[node.parent.data.name] = {
                x: node.parent.x - node.country_x,
                y: node.parent.y - node.country_y,
                r: node.parent.r
            }
        }
    })
    let done = {}

    Object.keys(names).forEach(function(key1){
        for (var i=0; i<Object.keys(done).length; i++){
            let key2 = Object.keys(done)[i];
            let node1 = names[key1];
            let node2 = names[key2];
            if (key1 != key2){
                let dis = calculate2NodeOverlapping(node2, node1)
                if (dis){
                    console.log(key1, key2, dis, node1, node2)
                    names[key1].x = names[key1].x + dis.x;
                    names[key1].y = names[key1].y + dis.y;
                    //i=0;
                }
            }
        }
        done[key1] = names[key1];
    })


    //console.log(names)
    return names;
};
