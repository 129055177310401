import * as d3 from "d3";
import {infoBar, infoBarSetValuation} from "../../infoBar";

export const defaults = {
    container: '#chart',
    height: 400,
    margin: {
        //top: 30, left: 60, bottom: 60, right: 30,
    },
    width: 400,
};

export default class startupBubble {
    isDoneSim = false
    simResult = null
    final_coord = {}
    needReCoord = false

    constructor(props) {
        this._init({ ...defaults, ...props });
    }

    _updateProps(props) {
        const {width, height, ...filteredProps} = props;
        this.props = { 
            ...this.props, 
            ...filteredProps, 
            width: defaults.width, 
            height: defaults.height ,
        };
        return this.props;
    }

    _init(props) {
        const {
            container,
            width,
            height,
            rDomain,
            continentColours
        } = this._updateProps(props);

        this.isDoneSim = false;
        /** build Info Bar */
        this.infoBar = infoBar(container, continentColours)
        let svgHeight = height;

        this.projection = d3.geoMercator()
            .scale(100)
            .translate([width/2,svgHeight/2]);
        //this.path = d3.geoPath().projection(this.projection)

        this.rScale = d3.scaleSqrt()
            .domain(rDomain)
            .range([0, 30]);

        this.pack = d3.pack()
            .size([width, svgHeight]).padding(1)

        //this.color = d3.scaleSequential(d3.interpolateRainbow).domain(rDomain);

        /** build svg */
        this.svg = d3.select(container)
            .append('svg')
            .attr('viewBox', `0 0 ${width} ${svgHeight}`);

        this.tooltip = d3.select(container).append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

    }

    render(props) {
        const {
            data,
            duration = 500,
            isLargest,
            isTop10,
            isWorld,
            isSector,
            isContinent,
            width,
            height,
        } = this._updateProps(props);

        const app = this;

        const root = d3.hierarchy(data)
            .sum(function(d) { return d.size; })
            .sort(function(a, b) { return b.value - a.value; });

        let nodes_leaves = app.pack(root).leaves();

        infoBarSetValuation(".infoBarValuation", nodes_leaves)

        nodes_leaves.forEach(function (node) {
            let coord = app.projection([node.data['Longitude'], node.data['Latitude']])
            node.country_x = coord[0]
            node.country_y = coord[1]
            //node.x = coord[0]
            //node.y = coord[1]
            if (!app.final_coord[node.data.Company]){
                app.final_coord[node.data.Company] = {
                    x: node.x - node.parent.x + node.country_x,
                    y: node.y - node.parent.y + node.country_y
                }
            }
        })

        let update = app.svg.selectAll("circle")
            .data(nodes_leaves)

        update.enter().append('circle')
            .attr("class", function (d) {
                return d.children ? "wnode" : "wleaf wnode";
            })
            .attr("r", d => d.r )
            .attr('cx', d => d.x - d.parent.x + d.country_x)
            .attr('cy', d => d.y - d.parent.y + d.country_y)

        d3.selectAll("circle.wnode").transition()
            .duration(duration)
            //.attr('transform', d => `translate(${d.finalX},${d.finalY})`)
            .attr('fill', d => {
                if (d.data.Company) {
                    return d.data['Colour Code']
                } else {
                    return "white"
                }
            })
            .attr('r', d => {
                return d.r;
            })

        var simulation = d3.forceSimulation()
            .force('x', d3.forceX(function (d) {
                return d.x - d.parent.x + d.country_x
            }).strength(0.7))
            .force('y', d3.forceY(function (d) {
                return d.y - d.parent.y + d.country_y
            }).strength(0.7))
            .force("collide", d3.forceCollide().radius((d) => d.r)).alphaDecay(0.07).stop()

        simulation.nodes(nodes_leaves)
            .on("tick", function (d) {
                d3.selectAll("circle.wnode")
                    .attr("cx", function (d) {
                        return d.x
                    })
                    .attr("cy", function (d) {
                        return d.y
                    })
            });

        simulation.on('end', () => {
            app.isDoneSim = true
            document.body.style.overflowY = 'auto';
            simulation.on('end', null)
            nodes_leaves.forEach(function (node) {
                node.finalX = node.x
                node.finalY = node.y
                app.final_coord[node.data.Company] = {
                    x: node.x,
                    y: node.y
                }
            })
        })

        if (isWorld){

            if (!this.isDoneSim){
                simulation.alpha(1).restart();
                document.body.style.overflowY = 'hidden';
            }
            if (app.needReCoord) {
                console.log("OK")
                d3.selectAll("circle.wnode").transition()
                    .duration(0)
                    .attr("cx", function(node) {
                        return node.x
                    })
                    .attr("cy", function(node) {
                        return node.y
                    })
                    .attr('fill', d => {
                        if (d.data.Company) {
                            return d.data['Colour Code']
                        } else {
                            return "white"
                        }
                    })
                    .attr('r', d => {
                        return d.r;
                    })
                app.needReCoord = false

            }
            if (isTop10) {
                const bubbles = d3.selectAll("circle.wnode");
                const topNBubRad = bubbles.nodes()
                    .filter(elm => elm.__data__.data['isTop'])
                    .map(elm => elm.__data__.r)
                    .sort((a, b) => b - a)

                const gap = 10;  // px, a hard coded hack-ish value
                // `/ 9` because 10 - 1 = 9
                // const gap = (width - topNBubRad.reduce((accu, cuur) => accu+cuur) * 2) / 9

                bubbles.transition()
                    .duration(duration)
                    .attr("cx", function(d) {
                        if (d.data['isTop']) {
                            const rankNum = d.data['isTop']
                            const xOffset = topNBubRad
                                .slice(0, rankNum-1)
                                .reduce((accu, curr) => accu + curr*2 + gap, 0)
                            // a hack to shift to left, because `width` (which is 400)
                            // is small than sum of diameters of the bubbles
                            return  xOffset - gap*1.5;
                        }
                        else{
                            if (app.final_coord[d.data.Company]){
                                return app.final_coord[d.data.Company].x
                            }
                            else {
                                return d.x - d.parent.x + d.country_x
                            }
                        }
                    })
                    .attr("cy", function(d) {
                        if (d.data['isTop']) {
                            return height * 0.75
                        }
                        else{
                            if (app.final_coord[d.data.Company]){
                                return app.final_coord[d.data.Company].y
                            }
                            else {
                                return d.y - d.parent.y + d.country_y
                            }
                        }
                    })
                    .style("opacity", d => {
                        return 1;
                    })
            }
            else{
                d3.selectAll("circle.wnode").transition()
                    .duration(duration)
                    .attr("cx", function(node) {
                        if (app.final_coord[node.data.Company]){
                            return app.final_coord[node.data.Company].x
                        }
                        else {
                            return node.x - node.parent.x + node.country_x
                        }

                    })
                    .attr("cy", function(node) {
                        if (app.final_coord[node.data.Company]){
                            return app.final_coord[node.data.Company].y
                        }
                        else {
                            return node.y - node.parent.y + node.country_y
                        }
                    })
                    .style("opacity", d => {
                        if (isLargest) {
                            if (d.data.isLargest) {
                                return 1;
                            } else {
                                return 0.2;
                            }
                        }
                        else {
                            return 1;
                        }
                    })
            }
        }

        if (isSector || isContinent) {
            app.needReCoord = true
            d3.selectAll("circle.wnode").transition()
                .duration(duration)
                .attr("cx", function(node) {
                    return node.x
                })
                .attr("cy", function(node) {
                    return node.y
                })
                .attr('fill', d => {
                    if (d.data.Company) {
                        return d.data['Colour Code']
                    } else {
                        return "white"
                    }
                })
                .attr('r', d => {
                    return d.r;
                })
                .style("opacity", d => {
                    if (isLargest) {
                        if (d.data.isLargest) {
                            return 1;
                        } else {
                            return 0.2;
                        }
                    } else {
                        return 1;
                    }
                })
        }

        // Set tooltip
        d3.selectAll("circle.wnode").on('mouseover', function (e, d) {
            if (d.data.Country) {
                app.tooltip.transition()
                    .duration(200)
                    .style("opacity", .9);
                app.tooltip.html("<div className='tooltip_content'>" +
                    "<p className='tooltip_title'>" +
                    d.data.name +
                    "<span className='w3-tag'> $" + d.value + "B</span>" +
                    "</p>" +
                    "<p className='tooltip_des'>" +
                    "<span className='tooltip_des_title'>Category: </span>" + d.data.Category +
                    "</p>" +
                    "<p className='tooltip_des'>" +
                    "<span className='tooltip_des_title'>Country: </span>" + d.data.Country +
                    "</p>"
                    )
                    .style("left", (e.offsetX + 10) + "px")
                    .style("top", (e.offsetY + 80) + "px");
                d3.select(this).style('opacity', 0.5);
            }
        })
            .on('mousemove', e => app.tooltip.style('top', `${e.offsetY + 80}px`)
                .style('left', `${e.offsetX + 10}px`))
            .on('mouseout', function (e, d) {
                if (isLargest) {
                    if (d.data.isLargest) {
                        d3.select(this).style('opacity', 1);
                    } else {
                        d3.select(this).style('opacity', 0.2);
                    }
                }
                else {
                    d3.select(this).style('opacity', 1);
                }

                app.tooltip.transition()
                    .duration(500)
                    .style("opacity", 0);
            })
        //update.exit().remove();

    }

    resize(props) {
        this.infoBar.remove();
        this.svg.remove();
        this.tooltip.remove();
        this._init(props);
        //this.render(props);
    }
}
