import * as d3 from "d3";
import { renderToString } from 'react-dom/server'
import {selectAll} from "d3-selection";

import { defaults as chartDefaults } from "./World/components/startupBubble";


export const infoBar = (container, continentColours) => {
    let infoBar = d3.select(container).append("div")
        .attr("class", "infoBar")

    let html = (
        <div className='infoBarContent'>
            <div className='infoBarSession'>
                <div className='infoBarSessionContent'>
                    <p>Geographical Locations</p>
                    <div className='infoBarSessionContainer'>
                        {continentColours.map((cc) => (
                        <div className='infoBarDetail geoLoc'>
                            <p>{cc.name}</p>
                            <div className="continentCircle" style={{backgroundColor: cc.code}}></div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='infoBarSession infoBarValuation'>
            </div>
        </div>
    )
    infoBar.html(renderToString(html))
    return infoBar
};

export const infoBarSetValuation = (container, node) => {
    let infoBar = d3.selectAll(container)
    const graphSvgEl = infoBar.node()
        .closest('div.graph_container')
        .querySelector('.graph_default svg');

    const scaleFactor = Math.min(
        graphSvgEl.getBoundingClientRect().width / chartDefaults.width,
        graphSvgEl.getBoundingClientRect().height / chartDefaults.height
    );
    const rSqToValRatio = Math.pow(node[0].r, 2) / node[0].value;

    let valuation = [1, 10, 25, 50]
    valuation = valuation.map(function (v) {
        return {
            name: v,
            d: Math.round(Math.sqrt(rSqToValRatio * v) * 2 * scaleFactor)
        }
    })

    let html = (
        <div className='infoBarSessionContent'>
            <p>Startup's Valuation (US billions)</p>
            <div className='infoBarSessionContainer'>
                {valuation.map((cc) => (
                    <div className='infoBarDetail valuation'>
                        <p>${cc.name}bn</p>
                        <div className="continentCircle" style={{border: "1px solid #000000", height: cc.d, width: cc.d}}></div>
                    </div>
                ))}
            </div>
        </div>
    )

    infoBar.html(renderToString(html))
    return infoBar
};
