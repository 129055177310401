import * as React from "react";
import { select, selectAll } from 'd3-selection';
import './App.css';
import './scss/style.scss';
import ScrollyTeller from '@ihmeuw/scrollyteller/src/ScrollyTeller/ScrollyTeller';
import World from './Sections/World/scrollyTellerConfig';
import { get } from 'lodash-es';

class App extends React.Component {
    storyConfig = {
        appContainerId: 'app', // div id="app"

        sectionList: [
            World,
        ],
        onNarrationChangedFunction: ({ narrationClass }) => {

            selectAll('.navButton')
                .classed('active', false);
            selectAll(`.navButton.${narrationClass}`)
                .classed('active', true);
        },
    };

    constructor(props) {
    super(props);
  }

  componentDidMount(){
    const storyInstance = new ScrollyTeller(this.storyConfig);
    storyInstance.render();

      selectAll('.navButton')
          .on('click', function () {
              const elt = select(this).node();
              storyInstance.scrollTo(
                  get(elt, ['dataset', 'section']),
                  get(elt, ['dataset', 'narrationtarget']),
              );
          });

  }

  render() {
    return (
        <div id="main">
          <div id="intro">
              <div style={{maxWidth: "85vw", margin: "auto"}}>
                  <p className="title">Visualizing the</p>
                  <p className="title" style={{fontSize: "6rem"}}>702 Unicorns</p>
                  <p className="title" style={{fontSize: "3rem"}}>Around the World</p>
                <p className="subTitle">By Jeff Ko, Louis, Kiu @Chartipedia  | Aug 15, 2021</p>
              </div>

              <div style={{backgroundColor: "#eeeeee"}}>
                <p className="content" style={{
                    marginTop: "15vh",
                    maxWidth: "62rem",
                }}>
                    Every cloud has a silver lining. While the COVID-19 breakout has shut down the world, the digital transformation has been accelerated. Startups are always striving to survive and some even defied the storm and became unicorns.
                    <br/>
                    A startup unicorn is a private company with a valuation over US$1billion. In this scrollytelling story, we are going to break down the world’s unicorn landscape.
                </p>
              </div>
              <p className="intro_scroll">
                  Start Scrolling!
              </p>
              <div style={{margin: "auto"}}>
                  <img className="intro_scroll_img" src={process.env.PUBLIC_URL + 'data/mouse.gif'}></img>
              </div>

              <img className="intro_img" src={process.env.PUBLIC_URL + 'data/unicorn.gif'}></img>
          </div>
            <nav className="navbar">
                <div className="tabs">
                    <div
                        data-section="global"
                        data-narrationtarget="Global"
                        className="navButton Global">
                        <div className="navButtonContent">
                            <div className="navButtonItem">
                                <h3>Global</h3>
                            </div>
                        </div>
                    </div>
                    <div data-section="global"
                         data-narrationtarget="Continent"
                         className="navButton Continent">
                        <div className="navButtonContent">
                            <div className="navButtonItem">
                                <h3>Continent</h3>
                            </div>
                        </div>
                    </div>
                    <div data-section="global"
                         data-narrationtarget="Sector"
                         className="navButton Sector">
                        <div className="navButtonContent">
                            <div>
                                <h3>Sector</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
          <div id="app">
          </div>

            <div id="footer" style={{backgroundColor: "#eeeeee"}}>
                <p className="footer-content" >
                    Data Source: CB Insights; Data as of August 03, 2021 <br/>
                    © Chartipedia
                </p>
            </div>
        </div>
    )
  }
}


export default App;
