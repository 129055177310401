import { isNil } from 'lodash-es'
import { interpolate } from 'd3-interpolate';
import { select } from 'd3-selection';
import { extent } from 'd3-array';
import groupBy from 'lodash/groupBy';
import './scss/startup.scss';
import startupBubble from "./components/startupBubble";
import {getContinentColours} from "../utils";


const global = { // Section Config object
  sectionIdentifier: 'global',

  // 1) DATA PATHS/PROMISES
  narration:  process.env.PUBLIC_URL + '/data/Global/narration.csv',
  data:  process.env.PUBLIC_URL + '/data/startup.json',

  // 2) FIRST RENDER
  reshapeDataFunction: function (data) {
    const countries = Array.from(new Set(data.map((d)=> {return d['Country']}))) ;

    var dataProcessed = data.map((d) => {
      return {
        ...d,
        name: d['Company'],
        size: parseInt(d["Valuation ($B)"].replace("$", "")),
        isLargest: false,
        isTop: false,
        Latitude: parseFloat(d['Latitude'].toString().replace("?", "")),
        Longitude: parseFloat(d['Longitude'].toString().replace("?", "")),
        //"Valuation ($B)": d["Valuation ($B)"].replace("$", "")
        //"Select Investors": d["Select Investors"].split(", ")
      };
    });

    let continentColours = getContinentColours();

    // top 10
    dataProcessed.sort(function(a, b) {
      return b.size - a.size;
    });
    let n = 10

    for (let i=0; i< n; i++){
      dataProcessed[i]['isTop'] = i + 1;  // start from 1, as 0 is falsy
    }

    // R min max
    const rDomain = extent(dataProcessed.map((d) => d.size, []));
    //console.log(rDomain)

    // Group by Country
    let dataCountry = JSON.parse(JSON.stringify(dataProcessed));
    dataCountry = groupBy(dataCountry, 'Country')

    // Mark largest
    Object.keys(dataCountry).forEach(function(key){
      dataCountry[key].sort(function(a, b) {
        return b.size - a.size;
      });
      dataCountry[key][0]['isLargest'] = true;
    })

    dataCountry = Object.keys(dataCountry).map((d) => {
      return {
        name: d,
        children: dataCountry[d]
      }
    });

    // Group by Sector
    let dataSector = JSON.parse(JSON.stringify(dataProcessed));
    dataSector = groupBy(dataSector, 'Category')

    // Mark largest
    Object.keys(dataSector).forEach(function(key){
      dataSector[key].sort(function(a, b) {
        return b.size - a.size;
      });
      dataSector[key][0]['isLargest'] = true;
    })

    dataSector = Object.keys(dataSector).map((d) => {
      return {
        name: d,
        children: dataSector[d]
      }
    });

    // Group by Continent
    let dataContinent = JSON.parse(JSON.stringify(dataProcessed));
    dataContinent = groupBy(dataContinent, 'Continent')

    // Mark largest
    Object.keys(dataContinent).forEach(function(key){
      dataContinent[key].sort(function(a, b) {
        return b.size - a.size;
      });
      dataContinent[key][0]['isLargest'] = true;
    })

    dataContinent = Object.keys(dataContinent).map((d) => {
      return {
        name: d,
        children: dataContinent[d]
      }
    });

    return {
      worldView: {
        name: "World View",
        children: dataCountry
      },
      continentView: {
        name: "Continent View",
        children: dataContinent
      },
      sectorView: {
        name: "Sector View",
        children: dataSector
      },
      continentColours,
      countries,
      rDomain
    }
  },

  buildGraphFunction: function (graphId, sectionConfig) {
    const {
      data: {
        worldView,
        continentColours,
        countries,
        rDomain
      },
    } = sectionConfig;

    const graphSelector = `#${graphId}`;

    const graph = new startupBubble({
      container: graphSelector,
      data: worldView,
      continentColours,
      countries,
      rDomain,
    });

    return graph;
  },

  // 3) ON USER EVENTS
  onActivateNarrationFunction: function ({sectionConfig,
                                         state: { isLargest, isSector, isContinent, isWorld, isTop10 }}) {
    const {
      data: {
        worldView,
        continentView,
        sectorView,
      },
        graph
    } = sectionConfig;

    let data_graph = worldView

    if (isWorld) {
      data_graph = worldView
    }
    else if (isContinent){
      data_graph = continentView
    }
    else if (isSector){
      data_graph = sectorView
    }

    graph.render({data: data_graph, isWorld: isWorld, isSector: isSector, isContinent: isContinent, isTop10: isTop10, isLargest:isLargest, duration: 500 });

  },
  onScrollFunction: function ({ data, graph }) {},
  onResizeFunction: function ({ data, graph, sectionConfig }) {
    let container = sectionConfig.graph.props.container

    sectionConfig.graph.resize({
      ...sectionConfig.graph.props,
      height: select(container).node().offsetHeight,
      width: select(container).node().offsetWidth * 0.9,
    })
  },

}; // end Section Config object

/** section configuration object with identifier, narration, and data (for the graph)  */
export default global;
